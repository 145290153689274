
export const ROUTER_ROUTE_CHANGE = 'ROUTER/ROUTE_CHANGE';

export const UI_SWITCH_USER_SIGNUP = 'UI/SWITCH_USER_SIGNUP';
export const UI_SWITCH_USER_LOGIN = 'UI/SWITCH_USER_LOGIN';
export const UI_SWITCH_USER_PREFERENCE = 'UI/SWITCH_USER_PREFERENCE';
export const UI_SWITCH_TAG_SIDEBAR = 'UI/UI_SWITCH_TAG_SIDEBAR';
export const UI_SWITCH_TOPIC_HEADER = 'UI/SWITCH_TOPIC_HEADER';
export const UI_CHANGE_ARTICLE_ORDER = 'UI/CHANGE_ARTICLE_ORDER';
export const UI_SWITCH_ARTICLE_MINIMIZED = 'UI/SWITCH_ARTICLE_MINIMIZED';
export const UI_CHANGE_CURRENT_ARTICLES = 'UI/CHANGE_CURRENT_ARTICLES';
export const UI_CHANGE_TAG_ORDER = 'UI/CHANGE_TAG_ORDER';
export const UI_CHANGE_META_TAGS = 'UI/CHANGE_META_TAGS';

export const USER = 'USER';
export const USER_FETCH_INIT = 'USER/FETCH_INIT';
export const USER_FETCH_SUCCESS = 'USER/FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER/FETCH_ERROR';
export const USER_CHANGE_INIT = 'USER/CHANGE_INIT';
export const USER_CHANGE_SUCCESS = 'USER/CHANGE_SUCCESS';
export const USER_CHANGE_ERROR = 'USER/CHANGE_ERROR';
export const USER_RECENTS = 'USER_RECENTS';
export const USER_RECENTS_CHANGE_INIT = 'USER/RECENTS_CHANGE_INIT';
export const USER_RECENTS_CHANGE_SUCCESS = 'USER/RECENTS_CHANGE_SUCCESS';
export const USER_RECENTS_CHANGE_ERROR = 'USER/RECENTS_CHANGE_ERROR';

export const BOOKMARK = 'BOOKMARK';
export const BOOKMARK_FETCH_INIT = 'BOOKMARK/FETCH_INIT';
export const BOOKMARK_FETCH_SUCCESS = 'BOOKMARK/FETCH_SUCCESS';
export const BOOKMARK_FETCH_ERROR = 'BOOKMARK/FETCH_ERROR';
export const BOOKMARK_ADD = 'BOOKMARK/ADD';
export const BOOKMARK_DELETE = 'BOOKMARK/DELETE';

export const TOPIC = 'TOPIC';
export const TOPIC_FETCH_INIT = 'TOPIC/FETCH_INIT';
export const TOPIC_FETCH_SUCCESS = 'TOPIC/FETCH_SUCCESS';
export const TOPIC_FETCH_ERROR = 'TOPIC/FETCH_ERROR';
export const TOPIC_CHANGE_INIT = 'TOPIC/CHANGE_INIT';
export const TOPIC_CHANGE_SUCCESS = 'TOPIC/CHANGE_SUCCESS';
export const TOPIC_CHANGE_ERROR = 'TOPIC/CHANGE_ERROR';

export const TAG = 'TAG';
export const TAG_FETCH_INIT = 'TAG/FETCH_INIT';
export const TAG_FETCH_SUCCESS = 'TAG/FETCH_SUCCESS';
export const TAG_FETCH_ERROR = 'TAG/FETCH_ERROR';
export const TAG_CHANGE_INIT = 'TAG/CHANGE_INIT';
export const TAG_CHANGE_SUCCESS = 'TAG/CHANGE_SUCCESS';
export const TAG_CHANGE_ERROR = 'TAG/CHANGE_ERROR';
export const TAG_FILTER_SIDEBAR = 'TAG/FILTER_SIDEBAR';
export const TAG_SET_CURRENT_TAGS = 'TAG/SET_CURRENT_TAGS';

export const ARTICLE = 'ARTICLE';
export const ARTICLE_FETCH_INIT = 'ARTICLE/FETCH_INIT';
export const ARTICLE_FETCH_SUCCESS = 'ARTICLE/FETCH_SUCCESS';
export const ARTICLE_FETCH_ERROR = 'ARTICLE/FETCH_ERROR';
export const ARTICLE_EDITION = 'ARTICLE/EDITION';
export const ARTICLE_CHANGE_INIT = 'ARTICLE/CHANGE_INIT';
export const ARTICLE_CHANGE_SUCCESS = 'ARTICLE/CHANGE_SUCCESS';
export const ARTICLE_CHANGE_ERROR = 'ARTICLE/CHANGE_ERROR';
export const ARTICLE_RECOMMENDATIONS = 'ARTICLE/RECOMMENDATIONS';
export const ARTICLE_TRACKING = 'ARTICLE/TRACKING';
export const ARTICLE_LANGUAGE = 'ARTICLE/LANGUAGE';
export const ARTICLE_HISTORY = 'ARTICLE/HISTORY';
export const ARTICLE_RESTORE = 'ARTICLE/RESTORE';
export const ARTICLE_TITLE_CONTENT = 'ARTICLE/TITLE_CONTENT';

export const COMMENT = 'COMMENT';
export const COMMENT_FETCH_INIT = 'COMMENT/FETCH_INIT';
export const COMMENT_FETCH_SUCCESS = 'COMMENT/FETCH_SUCCESS';
export const COMMENT_FETCH_ERROR = 'COMMENT/FETCH_ERROR';
export const COMMENT_CHANGE_INIT = 'COMMENT/CHANGE_INIT';
export const COMMENT_CHANGE_SUCCESS = 'COMMENT/CHANGE_SUCCESS';
export const COMMENT_CHANGE_ERROR = 'COMMENT/CHANGE_ERROR';

export const SEARCH_AUTOCOMPLETE = 'SEARCH_AUTOCOMPLETE';
export const SEARCH_AUTOCOMPLETE_QUERY = 'SEARCH_AUTOCOMPLETE/QUERY';
export const SEARCH_AUTOCOMPLETE_FETCH_INIT = 'SEARCH_AUTOCOMPLETE/FETCH_INIT';
export const SEARCH_AUTOCOMPLETE_FETCH_SUCCESS = 'SEARCH_AUTOCOMPLETE/FETCH_SUCCESS';
export const SEARCH_AUTOCOMPLETE_FETCH_ERROR = 'SEARCH_AUTOCOMPLETE/FETCH_FAILED';
export const SEARCH_AUTOCOMPLETE_ACTION = 'SEARCH_AUTOCOMPLETE/ACTION';
export const SEARCH_AUTOCOMPLETE_TAG_SELECTED = 'SEARCH_AUTOCOMPLETE/TAG_SELECTED';

export const SEARCH_SEARCH_QUERY = 'SEARCH/SEARCH_QUERY';
export const SEARCH_FETCH_INIT = 'SEARCH/FETCH_INIT';
export const SEARCH_FETCH_ERROR = 'SEARCH/FETCH_FAILED';
export const SEARCH_FETCH_SUCCESS = 'SEARCH/FETCH_SUCCESS';
export const SEARCH_TAG_SELECTED = 'SEARCH/TAG_SELECTED';
export const SEARCH_URL_SCRAP = 'SEARCH/URL_SCRAP';

export const ADMIN_META_SEARCH_INIT = 'ADMIN/META_SEARCH_INIT';
export const ADMIN_META_SEARCH_SUCCESS = 'ADMIN/META_SEARCH_SUCCESS';
export const ADMIN_VISIT = 'ADMIN_VISIT';
export const ADMIN_VISIT_FETCH_INIT = 'ADMIN_VISIT/FETCH_INIT';
export const ADMIN_VISIT_FETCH_SUCCESS = 'ADMIN_VISIT/FETCH_SUCCESS';
export const ADMIN_VISIT_FETCH_ERROR = 'ADMIN_VISIT/FETCH_ERROR';
export const ADMIN_BLOG = 'ADMIN_BLOG';
export const ADMIN_BLOG_FETCH_INIT = 'ADMIN_BLOG/FETCH_INIT';
export const ADMIN_BLOG_FETCH_SUCCESS = 'ADMIN_BLOG/FETCH_SUCCESS';
export const ADMIN_BLOG_FETCH_ERROR = 'ADMIN_BLOG/FETCH_ERROR';
export const ADMIN_BLOG_CHANGE_INIT = 'ADMIN_BLOG/CHANGE_INIT';
export const ADMIN_BLOG_CHANGE_SUCCESS = 'ADMIN_BLOG/CHANGE_SUCCESS';
export const ADMIN_BLOG_CHANGE_ERROR = 'ADMIN_BLOG/CHANGE_ERROR';
export const ADMIN_SEO_DATA = 'ADMIN_SEO_DATA';
export const ADMIN_SEO_DATA_FETCH_INIT = 'ADMIN_SEO_DATA/FETCH_INIT';
export const ADMIN_SEO_DATA_FETCH_SUCCESS = 'ADMIN_SEO_DATA/FETCH_SUCCESS';
export const ADMIN_SEO_DATA_FETCH_ERROR = 'ADMIN_SEO_DATA/FETCH_ERROR';
export const ADMIN_SEO_DATA_CHANGE_INIT = 'ADMIN_SEO_DATA/CHANGE_INIT';
export const ADMIN_SEO_DATA_CHANGE_SUCCESS = 'ADMIN_SEO_DATA/CHANGE_SUCCESS';
export const ADMIN_SEO_DATA_CHANGE_ERROR = 'ADMIN_SEO_DATA/CHANGE_ERROR';
